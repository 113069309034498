@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  h1 {
    @apply text-2xl font-[atten-new];
  }
  h2{
    @apply text-3xl font-extrabold text-black font-[atten-new]
  }
  body{
    @apply bg-gray-50
  }
  .grad{
    @apply bg-gradient-to-r from-cyan-500 to-blue-500 

  }

  .back1{
    @apply bg-slate-900

  }
  .text1{
    @apply text-slate-900 

  }
  .border1{
    @apply border-slate-900 

  }

  .altback1{
    @apply bg-emerald-500

  }
  .alttext1{
    @apply text-emerald-500
  }
  .altborder1{
    @apply border-emerald-500

  }

  
}


#tsparticles canvas{
  background-image: linear-gradient(to right, var(--tw-gradient-stops))!important;
    --tw-gradient-from: #f9fafb!important;
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(6 182 212 / 0))!important;
    --tw-gradient-to: #f9fafb!important;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  
  border-left: 20px solid #10B981;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#tsparticles canvas{
  position: absolute!important;

}


.hero-height{
  height: calc(100vh - 235.33px)
}



@media only screen and (max-width: 640px) {
  .hero-height{
    height: calc(100vh - 600.67px)
  }  
}



html {
  scroll-behavior: smooth;
}

.new-client{
  animation-name: noti;
  animation-duration: 2s;
  position: relative;
  z-index: 1;


}



.old-client1{
  animation-name: noti-old;
  animation-duration: 1s;

}

.line-expand{
  width: 100%!important;


}
@media only screen and (max-width: 1536px) {
  .line-expand{
    width: 95%!important;
  
  
  }
}


.ball1{
  opacity:1!important;
}
.ball2{

  opacity:1!important;
}
.ball3{
  opacity:1!important;
}
.arrow-anim{
  opacity:1!important;
}

@keyframes noti {
  from {opacity:0;margin-right: -400px;margin-left:400px;background-color: #10B981;}
  to {opacity:1;margin-right: 0px;margin-left:0px; background-color: #E5E7EB;}
}

@keyframes line-expand {
  from {width:0px}
  to {width:100%}
}

@keyframes noti-old {
  from {margin-top: -58px;}
  to {margin-right: 0px;}
}


.circle-pulse{
  animation-name: cpulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;

}
@keyframes cpulse {
  0%   {transform: scale(1.0);}
  50%  {transform: scale(1.2);}
  100% {transform: scale(1.0);}
}

@keyframes balls {

  0%   {opacity: 0;}
  90%  {opacity: 0;}
  100% {opacity: 1;}

}







.cursor-anim{
  animation-iteration-count: infinite;

  animation-name: cursor;
  animation-duration: 5s;
}

.cursor-anim:after{
  animation-iteration-count: infinite;

  animation-name: cursor-flare;
  animation-duration: 5s;
  content: url("./flare.svg");
  position: absolute;
  width:40px;
  height:auto;
  top:-20px;
  left:-20px;
opacity: 0;
}

@keyframes cursor {

  0%   {left: 100px; bottom:100px}
  50%   {left: 670px; bottom:360px}
  55%   {left: 670px; bottom:360px; transform: scale(1);}
  60%   {left: 670px; bottom:360px; transform: scale(0.8); opacity:0.9}
  65%   {left: 670px; bottom:360px; transform: scale(1);}
  70%   {left: 670px; bottom:360px; transform: scale(1);opacity:1}
  75%   {left: 670px; bottom:360px; transform: scale(1);opacity:0}
  90%   {left: 100px; bottom:100px; transform: scale(1);opacity:0}
 100%   {left: 100px; bottom:100px;opacity:1}


}

@keyframes cursor-flare {

  0%   {opacity:0}
  50%   {opacity:0}
  55%  {opacity:0}
  60%   {opacity:1}
  65%   {opacity:0}
  100%   {opacity:0}


}

